"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    app: {
        version: 'Version {{version}}',
        caseDesigner: {
            form: 'Form',
            caseAttribute: 'Case Attribute',
            formHint: "Please click on the Add button to add a new Form Question. You can add as many questions as you want. You can reposition the questions by using the Up and Down arrows next to each question. When you're done adding the questions, click Save to store the questions you created.",
            caseAttributeHint: "Please click on the Add button to add a new Case Attribute. You can add as many attributes as you want. You can reposition the attributes by using the Up and Down arrows next to each attribute. When you're done adding the attributes, click Save to store the attributes you created.",
            sharedCaseAttributeHint: "Please click on the Add button to add a new Shared Attribute. You can add as many shared attributes as you want. You can delete an attribute by clicking on the Trash icon on the question's rightmost Sections. When you're done adding the attributes, click Save to store the attributes you created.",
            selectHint: "This is a single-select question. It allows the user to select a single option out of the existing options. To enter the options, please use the text box below. Each option should be written in a single line. When you're done writing an option, press enter on your keyboard to create a new option and start writing it.",
            selectManyHint: "This is a multi-select question. It allows the user to select a multiple options out of the existing options. To enter the options, please use the text box below. Each option should be written in a single line. When you're done writing an option, press enter on your keyboard to create a new option and start writing it.",
            cascadingSelectHint: "This is a cascading-select question. It allows the user to select a series of options from several lists of options, each option list depending on the option selected in the previous list. You can enter an option by writing the option and then pressing the Enter button on your keyboard. To eneter the option list of an option, click on the option you'd like to create a new list for, then click + to add a new list for the selected option. You can enter the list of options in the same manner described before.",
            sharedAttributeDesigner: 'Shared Attributes Designer',
            formBody: 'Form Body',
            string: 'String',
            boolean: 'Boolean',
            number: 'Number',
            label: 'Label',
            paragraph: 'Paragraph',
            date: 'Date',
            image: 'Image',
            audio: 'Audio',
            select: 'Select',
            selectMany: 'Select Many',
            cascadingSelect: 'Cascading Select',
            whatsapp: 'WhatsApp Number',
            question: 'Question',
            configure: 'Configure',
            type: 'Type',
            required: 'Required',
            secret: 'Secret',
            internal: 'Internal',
            active: 'Active',
            file: 'File',
            delete: 'Delete',
            deleteQuestionAudio: 'Delete Question Audio',
            AreYouSureDeleteQuestionAudio: "Are you sure you'd like to delete the audio file for this question?",
            pageTitle: 'Page Title ',
            pageSelect: 'Question Page',
            options: 'Options',
            addNewPage: 'Add New Page',
            questions: 'Questions',
            title: 'Title',
            selectAudioFile: 'Audio ',
            editUsers: 'Who can edit this field',
            viewUsers: 'Who can view this field',
            optionTitle: 'option title {{lang}}',
            optionLabel: 'option label {{lang}}',
            cantSaveFieldWithotLocalization: 'You cant save field without localization',
            settings: 'Settings',
            acceptAudioAnswers: 'Accept Audio Answers',
            submittFirstForm: 'Submit First Page Form',
            deleteComfirm: 'Delete Confirmation',
            AreYouSureDeleteThisField: 'Are You Sure Delete This Field?',
        },
        common: {
            export: 'Export',
            name: 'Name',
            actions: 'Actions',
            edit: 'Edit',
            configure: 'Configure',
            save: 'Save',
            cancel: 'Cancel',
            email: 'Email',
            phoneNumber: 'Phone Number',
            confirm: 'Confirm',
            active: 'Active',
            unknown: 'Unknown',
            timeCreated: 'Time Created',
            password: 'Initial Password ',
            userAdded: 'User added successfully!',
            userNotAdded: 'Error! user not added.',
            projectAssignedSuccessfully: 'Project assigned successfully!',
            assigningProjectFailed: 'Failed to assign project!',
            deletedSuccessfully: 'Deleted successfully!',
            unableToDelete: 'Unable to delete!',
            savedSuccessfully: 'Saved successfully!',
            updatedSuccessfully: 'Updated successfully!',
            fileUploadSuccessfully: 'File Upload successfully!',
            approvedSuccessfully: 'Approved Successfully',
            unableToSave: 'Unable to save!',
            delete: 'Delete',
            copied: 'URL copied! ',
            users: 'Users',
            add: 'Add',
            view: 'View',
            yes: 'Yes',
            no: 'No',
            areYouSureToLogout: 'Are you sure you wont to Logout',
            viewCase: 'View Case',
            design: 'Design',
            ki: 'kinyarwanda',
            approve: 'Approve',
            myProfile: 'My Profile',
            changePassword: 'Change Password',
            logout: 'logout',
            createdAt: 'Created At',
            settings: 'Settings',
            closed: 'Closed',
            open: 'Open',
            hashtag: 'Hashtag',
        },
        caseAssignment: {
            project: 'Project',
            managers: 'Managers',
            responsible: 'Responsible',
            onlyViewUsers: 'Only View Users',
            onlyInternalUsers: 'Only Internal Users',
            onlyPublicUsers: 'Only Public Users',
        },
        layoutTitles: {
            home: 'Home',
            login: 'login',
            resetPassword: 'Reset Password',
            changePassword: 'Change Password',
            companyEdit: 'Company Edit',
            companyView: 'Company View',
            createCompany: 'Create Company',
            companiesTable: 'Companies Table',
            createCase: 'Create Case',
            caseView: 'Case View',
            homeDashboard: 'Dashboard',
            caseTypesManagement: 'Case Types Management',
            userPermissions: 'User Permissions',
            userProfileEdit: 'User Profile Edit',
            userProfileView: 'User Profile View',
            usersTable: 'Users Table',
            beneficiaryProfileEdit: 'Beneficiary Profile Edit',
            beneficiaryProfileView: 'Beneficiary Profile View',
            beneficiariesTable: 'Beneficiaries Table',
            myProfile: 'My Profile',
            regionsTable: 'Regions Table',
            projectsTable: 'Projects Table',
            groupPermissions: 'Group Permissions',
            caseTypeCasesTable: 'Case Type Cases Table',
            satisfactions: 'Satisfactions',
            groupsTable: 'Groups Table',
            casesTypesTable: 'Cases Types Table',
            caseTypesList: 'Case Types List',
            learningModules: 'Learning Modules',
            learningModuleChaptersTable: 'Learning Module Chapters Table',
            learningModulesPagesTable: 'Learning Modules Pages Table',
            learningModulesExamDesign: 'Learning Modules Exam Design',
            learningModulesSectionsTable: 'Learning Modules Sections Table',
            sectionsPagesTable: 'Sections Pages Table',
            caseTypesManagmentAssign: 'Case Types Management Assign',
            caseTypeDesign: 'Case Type Design',
            sharedAttributes: 'Shared Attributes',
            publicationsBlogTable: 'Publications Blog Table',
            publicationsBlogEditor: 'Publications Blog Editor',
            spacesTable: 'Spaces Table',
            dashboardDesigner: 'Dashboard Designer',
            reports: 'Reports',
            reportsDesigner: 'Reports Designer',
            portalUserProfileDesigner: 'Portal User Profile Designer',
            beneficiaryProfileDesigner: 'Beneficiary Profile Designer',
            communitySpaceHashtags: 'Community Space Hashtags',
            communitySpacePostsTable: 'Community Space Posts Table',
            communitySpaceFeed: 'Community Space Feed',
            pendingApprovalPosts: 'Pending Approval Posts',
            learningModulePages: 'Learning Module Pages',
            languages: 'Languages',
            languageSettings: 'Language Settings',
            emails: 'Emails',
            massTargeting: 'Mass Targeting',
            broadcasting: 'Broadcasting',
            broadcastingSms: 'Broadcasting SMS',
            crowdsourcing: 'Crowdsourcing',
            crowdsourcingSms: 'Crowdsourcing SMS',
            audience: 'Audience',
            learningModulesExamTake: 'Learning Modules Exam Take',
            learningModulesExamTakeGuideLines: 'Learning Modules Exam Take Guide Lines',
            learningModulesExamLastPage: 'Learning Modules Exam Last Page',
        },
        auth: {
            header: 'Login',
            login: 'Login',
            email: 'Email',
            notValidEmail: 'Not a valid email address!',
            enterEmail: 'Enter your email address!',
            password: 'Password',
            enterPassword: 'Enter your password!',
            enterCurrentPassword: 'Enter your current password!',
            enterNewPassword: 'Enter your new password!',
            enterConfirmedPassword: 'Confirm your new password!',
            forgetPassword: 'Forget Password',
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            confirmNewPassword: 'Confirm New Password',
            confirmButton: 'Confirm',
            passwordUpdated: 'Password updated!',
            checkPassword: 'Check Your Password',
            checkEmail: 'Check Your Email',
            wrongEmail: 'Wrong email address!',
            verify: 'Verify',
            verifyEmail: 'Verification Email',
            loginSuccess: 'Login successful!',
            loginUnsuccess: 'Login unsuccessful!',
            back: 'Back',
            accountDisabled: 'Unable to login, your account is inactive!',
            wrongPassword: 'Unable to login, the password you entered is not correct!',
            changePassword: 'Change Password',
            youHaveNoPerrmissionToLogIn: 'You have no permission to Login',
            thisAccountNotActive: 'This Account Is Not Active',
            thisAccountNotApproved: 'This Account Is Not Approved',
            caseTypeAddedcaseTypeAdded: 'Case type added',
        },
        projects: {
            delete: 'Delete',
            projectName: 'Project Name',
            userName: 'Username',
            edit: 'Edit',
            selectProjectButton: 'Select Project',
            titleSelect: 'Selecting project',
            selectUserButton: 'Select User',
            titleEditing: 'Editing project!',
            situation: 'Edit',
            addNewProject: 'Add New Project',
            addingNewProject: 'Adding new project',
            duplicateProjectFound: 'You already have a project with that name!',
            addingPlaceholder: 'Project Name',
            noteMessage: 'Please note that you cannot delete a project after adding it. However, you can still deactivate the project in the future.',
            editingNameOfProject: 'Editing name of project',
            editingPlaceholder: 'New Project Name',
            endDate: 'End Date',
            deadLineDays: 'Deadline (in Days)',
            name: 'Name',
            projectsTable: 'Projects Table',
            createAssigment: 'Create Assignment',
            addAudience: 'Add Audience',
            community: 'Community',
            tags: 'Tags',
            gender: 'Gender',
            phoneNumber: 'Phone Number',
            registerContact: 'Register Contact',
            noteMessageContact: 'Please note that you can edit user name or phone number to register contact',
        },
        blogs: {
            table: 'Posts Table',
            blog: 'Post',
            blogTitle: 'Post Title',
            addNewPost: 'Add New Post',
            blogPostSuccessfully: 'Post created successfully!',
            publish: 'Publish Post',
            upPublish: 'Update Post',
            delete: 'Delete Post',
            postTitleInput: 'Post Title',
            postDescriptionInput: 'Post Description',
            title: 'Post Title',
            description: 'Post Description',
            body: 'Post Body',
            edit: 'Edit',
            date: 'Date created',
            view: 'View',
            postedBy: 'Created by',
            areYouSureToDeleteThisPost: 'Are you sure you want to delete this post?',
            areYouSureToDeleteThisAttachment: 'Are you sure you want to delete this attachment?',
            errorDeletingThisPost: 'Error! Post deletion failed.',
            errorDelete: 'Error! Comment deletion failed.',
            areYouSureToDeleteThisReply: 'Are you sure you want to delete this comment?',
            areYouSureToApproveThisReply: 'Are you sure you want to approve this comment?',
            pleaseSelectSpace: 'Post Space',
            addedNewAttachment: 'Attachment added successfully!',
            attachments: 'Attachments',
            postContent: 'Post Content ',
            fullDisplay: 'Full Display',
            enterSomeText: 'Enter your post content here..',
            filesDrop: "Drag 'n' drop some files here, or click to select files",
            noTitle: 'No title for this Post in {{language}}',
        },
        satisfactions: {
            caseNumber: 'Case Number',
            caseType: 'Case Type',
            body: 'Body',
            rateSmail: 'Satisfactions',
            satisfactions: 'Satisfactions',
        },
        breadcrumb: {
            view: 'View',
            edit: 'Edit',
            sections: 'Sections',
            chapters: 'Chapters',
            modules: 'Modules',
            examdesign: 'Exam Design',
            pages: 'Pages',
            publications: 'Publications',
            blogs: 'Posts',
            users: 'Users',
            profile: 'Profile',
            casetypes: 'Case Types',
            beneficiaries: 'Beneficiaries',
            learningModules: 'Learning Modules',
            youAreIn: 'You are in:',
            correction: 'correction',
        },
        attachments: {
            addedNewAttachment: 'New Attachment',
            uploadFile: 'Upload File',
            selectFile: 'Select File',
            attachmentName: 'Attachment Name',
            enterAttachmentName: 'Attachment Name ({{language}})',
            attachmentUpdated: 'Attachment updated successfully!',
            fileNameNote: 'You cannot use these characters in the attachment name:',
        },
        reports: {
            report: 'Report',
            tilte: 'Title',
            addNewReport: 'Add new report',
            duplicateReportsFound: 'Duplicate Report Found',
            editingNameOfReports: 'Editing Report Name',
            areYouSureToDeleteThisReport: 'Are You Sure To Delete This Report',
            deleteSuccessfully: 'Delete Successfully',
            errorDeletingThisReport: 'Error Deleting This Report',
            reportName: 'Report Name',
        },
        spaces: {
            table: 'Topics Table',
            space: 'Topic Title',
            displayPortal: 'Show to Users',
            displayBeneficiary: 'Show to Beneficiaries',
            active: 'Active',
            date: 'Date created',
            addNewSpace: 'Add New Topic',
            editingNameOfSpace: 'Change Topic Title',
            duplicateSpaceFound: 'The Topic Title you provided already exists!',
            areYouSureToDeleteThisSpace: 'Are you sure you want to delete this Topic?',
            errorDeletingThisPost: 'Error! Topic deletion failed.',
            deleteSuccessfully: 'Topic deleted successfully!',
            documents: 'Posts',
        },
        modules: {
            table: 'Modules Table',
            modules: 'Module Title',
            displayPortal: 'Show to users',
            displayBeneficiary: 'Show to Beneficiaries',
            active: 'Active',
            date: 'Date created',
            addNewModule: 'Add New Module',
            editingNameOfModule: 'Change Module Title',
            duplicateModuleFound: 'The Module Title you provided already exists!',
            areYouSureToDeleteThisModule: 'Are you sure you want to delete this module?',
            errorDeletingThisPost: 'Error! Module deletion failed.',
            deleteSuccessfully: 'Module deleted successfully!',
            chapters: 'Chapters',
            requiredModuleToOpen: 'Module Prerequisite',
            duplicateModuleName: 'Duplicate Module Name Found',
            examDesign: 'Exam Design',
            selectOneModule: 'Select one Module',
            pages: 'Pages',
            uploadModuleIcon: 'Upload Module Icon',
            youNeedToFinishRequired: 'You need to finish the required module to open this module',
            examTake: 'Take Exam',
            youNeedToFinishThisModuleToTakeExam: 'You need to finish this module to take the exam',
            youCantStartExamUntil: 'You can not take the exam until',
            examNotCreatedYet: 'Exam not created yet',
        },
        examDesigner: {
            select: 'Single Choice Question',
            multiSelect: 'Multiple Choice Question',
            numberOfOptionsMinus: 'The number of answers to the question must be at least two!',
            numberOfCorrectOptions: 'The number of correct answers must be at least one!',
            addNewOption: 'Add new choice',
            optionTitle: 'Choice text',
            addNewQuestion: 'Add New Question',
            questionTitle: 'Question text',
            questionType: 'Question Type',
            EditNewQuestionModalTitle: 'Edit Question',
            newQuestionTitle: 'New Question',
            areYouSureDeleteThisQuestion: 'Are you sure that you want to delete this question?',
            areYouSureDeleteThisOption: 'Are you sure that you want to delete this choice?',
            noQuestionsYetHunt: 'No questions added yet.',
            designInfoInfiniteNumbers: 'You can add an infinite number of questions.',
            designInfoTwoOptions: 'A question must have at least two choices.',
            designInfoCorrectOptions: 'A correct answer must be specified for each question.',
            informationNots: 'Guidelines',
            option: 'option',
            active: 'Active',
            actions: 'Actions',
            correctAnswer: 'Correct Answer',
        },
        examsTake: {
            informationNots: 'Guidelines',
            next: 'Next',
            quitExam: 'Quit Exam',
            examStartInfo: 'Exam Start Info',
            markDown: `
    # Starting the Exam.
    
    You are about to take the exam of the **{{learningModuleTitle}}\u200B** module. By completing and successfully passing this exam, you'll receive a Certificate of Completion signed by IBJ in your name.
    The following are the steps you'll need to finish in order to successfully complete the exam and receive your certificate:
    
    1. Reading and accepting the exam conditions.
    2. Reading and accepting IBJ's Code of Honor.
    3. Reading the instructions on how to take the exam.
    4. Answering the exam questions.
    
    After answering the exam questions, the application will evaluate your answers in accordance with the exam conditions and determine whether you passed or failed the exam. If you pass the exam, you will be allowed to generate and download your certificate.
    
    # Exam rules and conditions.
    
    1. The exam consists of several multiple choice questions. In some questions, the correct answer is a single choice, and in other questions, the correct answer consists of several choices.
    2. To pass a learning module's exam and qualify for a certificate, your score must be 70% or more.
    3. You are allowed three attempts. If you fail at all three attempts, you'll be required to restudy the entire learning module. In that case, you won't be able to retake the exam for 7 days.
    4. After each failed attempt, you'll have to wait 8 hours before being allowed to retake the exam.
    
    # IBJ's code of honor
    
    All users of the JusticeHub  mobile application shall read and understand the International Bridges to Justice (“IBJ”) Honor Code prior to the commencement of any test. By reading and understanding the honor code, users agree to abide by its provisions, and abstain from dishonesty in any work hereinafter, and hence agree to ensure the integrity, credibility, and the fairness of the test, documents, and any and all related processes.
    
    ### Accounts
    
    Under the IBJ Honor Code, users are obliged to have only one account, linked to either their email or phone number for registration. Should there be a need for multiple accounts, please contact support@ibj.org.
    
    ### Tests
    
    Any form of dishonesty in taking any test and exam, by the user or another, will not be tolerated. Tests and exams are to be completed by the account holder and nobody else. Account holders may not share their answers or solutions to any other users.
    Account holders may not engage in any other activities that will dishonestly improve their results or dishonestly improve or damage the results of others. Such activities include but are not limited to copying information; looking for the information from another source while taking the test; and having another person apart from the account holder take the test for them.
    
    ### Plagiarism
    
    Plagiarism is the failure to acknowledge words, ideas, or other intellectual material or property gained from another piece of work. Plagiarism is strictly prohibited under the IBJ Honor Code.
    
    ### Reporting
    
    All account holders are obliged to report any and all violations of the previously mentioned conditions by contacting IBJ at support@ibj.org.
    
    # Exam instructions
    
    - You'll be answering the exam questions one at a time. After answering a question, press the **>** button to proceed to the next question.
    - You can return to previous questions and change your answers by pressing the **<** button.
    - At the bottom right corner, you can see which question is currently being displayed and how many questions in total there are.
    - After answering all questions, press the Next button to proceed to the Submission page.
    - To submit your answers for evaluation, press the **Submit answers button**. You will exhaust any of your attempts if you don't press that button.
    - In the submission page, you can press the **Preview answers** button to preview your answers to all questions.
    - You can press the **Change answers** button to return to the exam questions and change your answers. You can press the **>|** button to return to the Submission page.`,
            pleasePressStartExam: 'Please press Start Exam to begin taking the exam.!',
            question: 'Question',
            of: 'of',
            answeredAll: 'You have answered all the questions. You can now submit your answers.',
            previewAnswers: 'Preview Answers',
            submitAnswers: 'Submit Answers',
            changeAnswers: 'Change Answers',
            returnToModules: 'Return to Modules',
            yourAnswersIsSubmitted: 'Your answers have been submitted successfully!',
            passed: 'Passed',
            yorGradeIs: 'Your grade is',
            pressGenerate: 'Press Generate',
            pressGoToSectionsNote: 'Press Go To Sections Note',
            pressGoToReturnToModule: 'Press Go To Return To Module',
            generateCertificate: 'Generate Certificate',
            goToSections: 'Go To Sections',
            returnToModule: 'Return To Module',
            failed: 'Failed',
            remainingSubmission: 'Remaining Submission',
            time: 'Time',
            youCanTakeExamNow: 'You can take the exam now',
            retakeTheExam: 'Retake The Exam',
            exhaustedAllThree: 'You have exhausted all three attempts. You can retake the exam in 7 days.',
            yourGradeIs: 'Your grade is',
            closePreview: 'Close Preview',
        },
        chapters: {
            table: 'Chapters Table',
            chapter: 'Chapter Title',
            active: 'Active',
            date: 'Date created',
            addNewChapters: 'Add New Chapter',
            editingNameOfChapters: 'Change Chapter Title',
            duplicateChaptersFound: 'The Chapter Title you provided already exists!',
            areYouSureToDeleteThisChapters: 'Are you sure you want to delete this chapter?',
            errorDeletingThisPost: 'Error! Chapter deletion failed.',
            deleteSuccessfully: 'Chapter deleted successfully!',
            Sections: 'Sections',
            duplicateChapterName: 'Duplicate chapter name found!',
        },
        sections: {
            table: 'Sections Table',
            Sections: 'Section Title',
            active: 'Active',
            date: 'Date created',
            addNewSections: 'Add New Section',
            editingNameOfSections: 'Change Section Title',
            duplicateSectionsFound: 'The Section Title you provided already exists!',
            areYouSureToDeleteThisSections: 'Are you sure you want to delete this section?',
            errorDeletingThisPost: 'Error! Section deletion failed.',
            deleteSuccessfully: 'Section deleted successfully!',
            duplicateSectionFound: 'Duplicate section name found!',
            pages: 'Section Pages',
        },
        sectionsPages: {
            table: 'Sections Pages Table',
            Sections: 'Page Title',
            active: 'Active',
            date: 'Date created',
            addNewPage: 'Add New Page',
            editingNameOfSections: 'Change Page Title',
            duplicateSectionsFound: 'The Page Title you provided already exists!',
            areYouSureToDeleteThisSections: 'Are you sure you want to delete this page?',
            errorDeletingThisPost: 'Error! Page deletion failed.',
            deleteSuccessfully: 'Delete Section Page Successfully',
            pleaseEnterPageTitle: 'Please Enter Page Title',
            update: 'Update',
            save: 'Save',
            areYouSureToDeleteThisPage: 'Are you sure you want to delete this page?',
            delete: 'Delete',
            pageTitleInput: 'Page Title',
            enterPageContent: 'Write your page content here..',
            pageAddSuccessfully: 'Page added successfully!',
            pageUpdateSuccessfully: 'Page updated successfully!',
            pageNumber: 'Page Number',
            noTitle: '(No title provided for this page)',
        },
        pageDesign: {
            title: 'Page Title',
            body: 'Page Body',
            youHaveChanges: 'You have new changes. Do you want to save the changes?',
            discardChanges: 'discard changes',
            saveChanges: 'Save changes',
            pageContent: 'Page Content',
            pageNumber: 'Page Number',
            pageTitleInput: 'Page Title',
            page: 'Page',
            of: 'of',
            areYouSureToDeleteThisPage: 'Are you sure that you want to delete this page?',
            delete: 'Delete',
            save: 'Save',
            update: 'Update',
            enterSomePageText: 'Enter Some Page Text',
            prev: 'Prev',
            next: 'Next',
        },
        cases: {
            title: '{{caseType}} {{caseNumber}}',
            unknown: 'Unknown',
            mainInformation: 'Main Information',
            sharedAttributes: 'Shared Attributes',
            caseAttributes: 'Case Attributes',
            manager: 'Manager',
            managers: 'Managers',
            responsible: 'Responsible',
            seenByResponsible: 'Seen by responsible',
            submittedBy: 'Submitted By',
            caseNumber: 'Case Number',
            caseType: 'Case Type',
            date: 'Date',
            action: 'Action',
            project: 'Project',
            createCase: 'Create Case',
            formBody: 'Body',
            areYouSureChangeManager: 'Are you sure you want to change the Case Manager?',
            areYouSureChangeProject: 'Are you sure you want to change the Case Project?',
            areYouSureChangeCaseType: 'Are you sure you want to change the Case Type?',
            areYouSureToCloseThisCase: 'Are you sure you want to close this case?',
            areYouSureToROpenThisCase: 'Are you sure you want to open this case?',
            mustBeAtLeastOneManagerSelected: 'There should be at least one manager selected for the case!',
            closeCase: 'Close This Case',
            openCase: 'Open This Case',
            areYouSureChangeResponsible: 'Are you sure you want to change the Case Responsible?',
            submittedForm: 'Submitted Form',
            replies: 'Replies',
            closed: 'Closed',
            caseAuthor: 'Case Author',
            secret: 'Secret',
            internal: 'Reply to users and administrators',
            public: 'Public',
            publicToBeneficiary: 'Reply to beneficiary',
            setReplaySecret: 'Set Reply Secret',
            caseRead: 'Case read',
            lastReplayRead: 'Last reply read',
            lastAction: 'Last action',
            allNewRepliesSecret: 'Make all new replies secret?',
            caseLoge: 'Case Activity Log',
            deadLineDays: 'Deadline (in Days)',
            publicCreateCaseUrl: 'Public Create Case URL',
            anonymous: 'Anonymous',
            language: 'Language',
            RatedType: 'Beneficiary Rating: ',
            rateBody: 'Beneficiary Comments: ',
            rateDate: 'Date rated: ',
            waitingRate: "Waiting for the beneficiary's rating..",
            caseClosedSuccessfully: 'Case closed successfully!',
            errorClosingCase: 'Error closing the case!',
            subName: 'Submitted Name',
            subEmail: 'Submitted Email',
            addReplyForResponsible: 'add reply for responsible',
            addReplyForResponsibleDescrpiton: 'This message will be posted as an internal reply on the case.',
            subPhoneNumber: 'Submitted Phone',
            dir: 'ltr',
            bRate: 'Beneficiary Service Rated',
            dragColumn: 'Drag a column header here to group by that column',
            searchPlaceholder: 'Search',
            notAnsweredYet: 'Not Answered Yet.',
            questionReply: 'Question Reply Design',
            seenByResbonsible: 'Seen By responsible',
            caseOpenedSuccessfully: 'Case opened successfully',
            status: 'Status',
            caseTasks: 'Case Tasks',
            caseTask: 'Case Task',
            addNewTask: 'Add New Task',
            newCaseTask: 'New Case Task',
            caseTaskManagement: 'Case Task Management',
            casesTasksTable: 'Cases Tasks Table',
            relatedCase: 'Related Case',
            caseTaskStatus: 'Status',
            state: 'State',
            title2: 'Title',
            assignedTo: 'Assigned To',
        },
        submitCase: {
            required: 'Required',
            image: 'Please upload an image file!',
            audio: 'Please upload an audio file!',
            projectId: 'Please select a project!',
            caseTypeId: 'Please select a case type!',
            body: 'Please Describe your situation briefly!',
            bodyType: 'Please select a body type!',
            selectCompany: 'Select organization:',
            selectCaseType: 'Select case type:',
            selectProject: 'Select project:',
            submit: 'Submit',
            success: 'Submission successful!',
            fail: 'Submission failed!',
            confirm: 'Confirm',
            preview: 'Preview',
            cancel: 'Cancel',
            edit: 'Edit',
            file: 'Please select a file!',
            confirmAnonymous: 'Submit Anonymously',
        },
        assignments: {
            noProject: 'No Project',
            notSelected: 'Not selected',
            otherProjects: 'Other Projects',
            user: 'User',
            managers: 'Managers',
            internalSharingUsers: 'Internal Sharing Users',
            viewOnlyUsers: 'View Only Users',
            publicSharingUsers: 'Public Sharing Users',
            responsible: 'Responsible',
            projectName: 'Project Name',
            caseTypesAssignments: 'Case Types Assignments',
        },
        users: {
            userTable: 'User Table',
            canViewCommunitySpace: 'Community Space',
            editUserName: ' Edit User Name',
            newUserName: 'New Username:',
            addNewUser: 'Add New User',
            EnterUserInfo: 'Enter User Info:',
            noteMessage: 'Please note that you cannot delete a user after adding it. However, you can still deactivate the user in the future.',
            duplicateUserFound: 'You already have a user with this Username!',
            viewProfile: 'View Profile',
            notFound: 'User not found!',
            userProfile: 'User Profile',
            profile: 'Profile',
            sharedCases: 'Shared Cases',
            approved: 'Approved',
            nameRequired: 'The name field is required!',
            emailNotValid: 'Please enter a valid email address!',
            emailRequired: "Please enter the user's email address!",
            passwordRequired: 'Please enter password for the user!',
            profileNotFound: 'Profile not found!',
            pleaseEnterPhoneNumber: 'Please enter phone number',
            regions: 'Regions',
        },
        groups: {
            notFound: 'Not Found',
            groupsTable: 'Group Table',
            editGroupName: ' Edit:',
            editGroupUsers: 'Edit Group Users',
            newGroupName: 'New Group Name:',
            addNewGroup: 'Add new Group',
            EnterGroupInfo: 'Enter Group Info:',
            groupName: 'Group Name',
            groupNameRequired: 'The Group Name is required!',
            groupUsers: 'Group Users',
            noteMessage: 'Please note that you cannot delete a group after adding it. However, you can still deactivate the group in the future.',
            addedNewGroupMsg: 'Group added successfully!',
            duplicateGroupFound: 'You already have a group with this Name!',
        },
        permissions: {
            permissions: 'Permissions',
            manageUsers: 'Manage Users',
            manageGroups: 'Manage Groups',
            manageProjects: 'Manage Projects',
            sharedAttributes: 'Shared Attributes',
            submitCase: 'Submit Case',
            createNewCaseType: 'Create New Case Type',
            editCaseTypeDesign: 'Edit Case Type Design',
            changeAssignmentRolesInCaseType: 'Change Assignment Roles',
            editCaseTypesProjects: 'Edit Case Types Projects',
            editCompanyConfiguration: 'Edit Company Configuration',
            editPermissions: 'Edit Users Permissions',
            permissionToLoginFromMobile: 'Permission To Login From Mobile',
            permissionToLoginFromWebPortal: 'Permission To Login From Web Portal',
            documentLibraryManage: 'Publications Manage',
            viewPublications: 'View Publications',
            viewELearning: 'View e-Learning',
            exportCases: 'Export Cases',
            manageELearning: 'Manage e-Learning',
            beneficiariesView: 'Beneficiaries view',
            beneficiarySatisfactionView: 'Beneficiaries Satisfactions View',
            permissionsUpdated: 'Permissions Update Successfully',
            portalUsersProfileDesgin: 'Portal Users Profile Design',
            beneficiaresProfileDesgin: 'Beneficiaries Profile Design',
            dashboardDesigner: 'Dashboard Designer',
            manageBeneficiaries: 'Manage Beneficiaries',
            selectAll: 'Select All Permissions',
            removeAll: 'Remove All Permissions',
            manageCommunitySpace: 'Manage Community Space',
            manageRegions: 'Manage Regions',
            manageLanguages: 'Manage Languages',
            manageEmails: 'Manage Emails',
        },
        layout: {
            cases: 'Cases',
            users: 'Users',
            projects: 'Projects',
            communitySpace: 'Community Space',
            dashboard: 'Dashboard',
            integrations: 'integrations',
            caseTypesManagment: 'Configure Case Types',
            caseTypes: 'Case Types',
            changePassword: 'Change Password',
            logout: 'Logout',
            groups: 'Groups',
            footer: 'GeniusTags LTD',
            sharedAttributes: 'Shared Attributes',
            applications: 'Applications',
            createCase: 'Create Case',
            companies: 'Organizations',
            createCompany: 'Create Organization',
            spaces: 'Publications',
            createPortalAdmin: 'Create Portal Admin',
            portalUserProfileDesigner: 'Portal User Profile Designer',
            beneficiaryProfileDesigner: 'Beneficiary Profile Designer',
            myProfile: 'My Profile',
            beneficiaries: 'Beneficiaries',
            documentLibrary: "Publications' Library",
            eLearning: 'Learning Modules',
            home: 'Home',
            pages: 'Pages',
            view: 'View',
            edit: 'Edit',
            homeTitle: 'Genius Feedback',
            dashboardDesigner: 'Dashboard Designer',
            usersAndBeneficiary: 'Users and Beneficiary',
            // homeTitle: 'JusticeHub',
            somethingWentWrong: 'Something Went Wrong!',
            somethingWentWrongDescription: "Looks like this page is missing don't worry though our best man is on the case Report Error or back to home Page",
            reportFeedBack: 'Report FeedBack',
            backToHome: 'Back To Home ',
            labelEmail: 'Email',
            labelName: 'Name',
            whatsHappen: 'Whats Happen',
            reports: 'Reports',
            Satisfactions: 'Satisfactions',
            communitySpaceHashtags: 'Community Space Hashtags',
            communitySpacePosts: 'Community Space Posts',
            communitySpaceFeed: 'Community Space Feed',
            regions: 'Regions',
            pandingApprovePosts: 'Panging Approve Posts',
            massTargeting: 'Mass Targeting',
            audience: 'Audience',
            broadcasting: 'Broadcasting',
            broadcastingSms: 'Broadcasting SMS',
            crowdsourcing: 'Crowdsourcing',
            crowdsourcingSms: 'Crowdsourcing SMS',
            languages: 'Languages',
            caseTypesManagement: 'Case Types Management',
            emails: 'Emails',
            caseTaskManagement: 'Case Task Management',
            casesTasksTable: 'Cases Tasks Table',
            communityEngagement: 'Community Engagement',
        },
        usersRoles: {
            view: 'Can view case users',
            public: 'Can post public replies',
            internal: 'Can post internal replies',
        },
        caseNotification: {
            caseTypeId: ' Type',
            projectId: ' Project',
            manager: ' Managers',
            attributes: ' Attributes',
            responsible: '  Responsible',
            onlyInternalUsersIds: ' Only Post Internal Users',
            onlyViewUsersIds: ' Only View Users',
            onlyPublicUserIds: ' Only  Post Public User',
            hasSeenTheCase: 'Has Seen This Case',
            hasReplayedInTheCase: 'Has Replayed In The Case',
            hasClosedThisCase: 'Has Closed This Case',
            hasOpenedThisCase: 'Has Opened This Case',
            hasChangedTheCase: 'Has Changed The Case',
            hasSubmittedThisCase: 'Has Submitted This Case',
            before: 'Before',
            after: 'After',
            hasAddedNewTask: 'Has Added New Task',
            caseTaskStatus: 'Case Task Status',
        },
        quizzesResultsTable: {
            name: 'Name',
            quizzesResults: 'Quizzes Results',
            moduleName: 'Module Name',
            submtionDate: 'Submit Date',
            examMark: 'Exam Mark',
            table: 'Quizzes Results Table',
        },
        formats: {
            shortDateTime: '{{date, MM-DD HH:mm}}',
            fullDateTime: '{{date, YYYY-MM-DD HH:mm}}',
        },
        languages: {
            en: 'English',
            ar: 'Arabic',
            ki: 'kinyarwanda',
            addNewLanguage: 'Add New Language',
            duplicateLanguageFound: 'Duplicate language found',
            languageCreatedSuccessfully: 'Language Created successfully',
            name: 'Language Name (English)',
            localName: 'Language Name (Local)',
            langKey: 'Language Key',
            direction: 'Direction',
            percentage: 'Interface translation',
            ltr: 'Left To Right',
            rtl: 'Right To Left',
            import: 'Import',
            export: 'Export',
            downloadTemplate: 'Download Template',
            areYouSureToDeleteThisLanguage: 'Are You Sure To Delete This Language?',
            deleteSuccessfully: 'Language Deleted Successfully',
            errorDeletingThisLanguage: 'Error! language deletion failed.',
        },
        caseTypes: {
            addNewCaseType: 'Add New Case Type',
            editCaseType: 'Edit Case Type',
            title: 'Title',
            code: 'Code',
            edit: 'Edit',
            design: 'Design Form',
            assign: 'Assign Users',
            projects: 'Assign Projects',
            caseTypesManagementTable: 'Case Types Management Table',
            caseTypes: 'Case Types',
            specificCaseTypesTable: 'Case Type Projects',
            copyToAnotherRegion: 'Copy Case Type To Another Region',
            toRegion: 'To Region',
            avalibleToLawyers: 'Show To Users',
            avalibleToBeneficiaris: 'Show To Beneficiaries',
        },
        profileDesigner: {
            portalUserProfileDesigner: 'Portal User Profile Designer',
            portalUserProfileHint: 'This page allows you to specify the fields that will be required to be filled by Portal Users when they create new accounts.',
            beneficiaryProfileDesigner: 'Beneficiary Profile Designer',
            beneficiaryProfileHint: 'This page allows you to specify the fields that will be required to be filled by Beneficiaries when they create new accounts.',
            designer: 'Designer',
            settings: 'Profile Settings',
            profilePersonalActive: 'Profile Personal Information View/Edit:',
            profileExperienceActive: 'Profile Work Experience Active View/Edit:',
            profileEducationLearningActive: 'Profile Education And Learning Active View/Edit:',
            profileSkillsActive: 'Profile Skills Active View/Edit:',
            profileBadgesActive: 'Profile Badges Active View/Edit:',
        },
        beneficiaries: {
            beneficiaryProfile: 'Beneficiary Profile',
            profile: 'Profile',
            submittedCases: 'Submitted Cases',
            beneficiaries: 'Beneficiaries',
            viewProfile: 'View Profile',
            uid: 'Beneficiary ID',
            satisfactions: 'Satisfactions',
            notFound: 'Beneficiary not found!',
            beneficiaryProfileEdit: 'Beneficiary Profile Edit',
        },
        hashtags: {
            table: 'Hashtags Table',
            hashtagName: 'hashtag body',
            createdAt: 'Created At',
            createdBy: 'Created By',
        },
        communitySpace: {
            postView: 'Post View',
            body: 'Body',
            createdBy: 'Created by',
            date: 'Date',
            type: 'Post Type',
            action: 'Action',
            searchPlaceholder: 'Search Placeholder',
            dragColumn: 'Drag a column header here to group by that column',
            areYouSureToDeletePost: 'Are you sure you wont to Delete This Post',
            areYouSureToDeleteComment: 'Are you sure you wont to Delete This Comment',
            areYouSureToDeleteReply: 'Are you sure you wont to Delete This Reply',
            attachments: 'Attachments',
            interested: 'Interested',
            attending: 'Attending',
            notGoing: 'Not Going',
            title: 'Title',
            description: 'Description',
            pin: 'Pin Post',
            unpin: 'Unpin Post',
            comments: 'Comments',
            pandingApprovelPosts: 'Pending Approval Posts Table',
            pinned: 'Pinned',
            edited: 'Edited',
            follow: 'Follow',
            save: 'Save',
            saved: 'Saved',
            followed: 'Followed',
            post: 'Post',
            cancel: 'Cancel',
            addAPost: 'Add a post',
            clickOrDragFileToUpload: 'Click or drag file to this area to upload',
            uploadDescription: 'Support for a single or bulk upload.',
            attachmentType: 'Attachment Type',
            audio: 'Audio',
            video: 'Video',
            link: 'Link',
            image: 'Image',
            file: 'File',
            areYouSureYouWantToChangeAttachmentType: 'Are you sure you want to change attachment type?',
            unsavedProgressWillBeLost: 'Any unsaved progress will be lost!',
            uploadImage: 'Upload Image',
            attachMedia: 'Media',
            addPoll: 'Poll',
            createEvent: 'Event',
            shareCase: 'Case',
            createPetition: 'Petition',
            addAttachment: 'Add Attachment',
            pollOption: 'Poll Option',
            addOption: 'Add Option',
            newOption: 'New Option',
            poll: 'Poll',
            petition: 'Petition',
            address: 'Address',
            endDate: 'End Date',
            sign: 'Sign petition',
            unsign: 'Unsign petition',
            signedBy: 'Signed by',
            at: 'At',
            showReplies: 'Show Replies',
            fullName: 'Full Name',
            email: 'Email',
            bio: 'Biography',
            companyName: 'Company Name',
            position: 'Position',
            startDate: 'Start Date',
            birthdate: 'Birthdate',
            organization: 'Organization',
            whatsappNumber: 'Whatsapp contact number:',
            profileView: 'Profile View',
            postUploading: 'Your post is being uploaded.',
            event: 'Event',
            uploadingWait: 'Your file is being uploaded.',
            uploadingDone: 'File uploaded successfully',
        },
        region: {
            name: 'Name',
            regionsTable: 'Regions Table',
            regionName: 'Region Name',
            regionEdit: 'Region Edit',
            duplicateRegionFound: 'Duplicate Region Found',
            languages: 'languages',
            createRegion: 'Create Region',
            users: 'Users',
            countries: 'Available Countries',
            avalibleInAllCountries: 'Available In All Countries',
            areYouSureToDeleteThisRegion: 'Are you sure to delete this region?',
            deleteSuccessfully: 'Region Deleted Successfully',
            errorDeletingThisRegion: 'Error! region deletion failed.',
        },
        profile: {
            name: 'Name',
            whatsappNumber: 'WhatsappNumber',
            email: 'Email',
            Birthdate: 'Birth Date',
            bio: 'Bio',
            companyName: 'Company Name',
            positionTitle: 'Position Title',
            contractStartDate: 'Contract Start Date',
            contractEndDate: 'Contract End Date',
            description: 'Description',
            educationAndTrainig: 'Education and training',
            typeOfQualification: 'Type Of Qualification',
            awadingOrganization: 'Awading organization, institution',
            grade: 'Grade',
            startDate: 'Start Date',
            endDate: 'End Date',
            birthdate: 'Birth date',
            personalInformation: 'Personal Information',
            workExperience: 'Work Experience',
            educationAndTraining: 'Education And Training',
            profileDesignFields: 'Profile Design Fields',
            phoneNumber: 'Phone Number',
            organization: 'Organization',
            badges: 'Badges',
            skills: 'Skills',
            genralInformations: `General Information's`,
            successfulCompletionModule: 'Successful Completion of an E-Learning Module ( {{moduleName}} )',
        },
        emails: {
            email: 'Email:',
            addNewEmail: 'Add New Email',
            emailRequired: 'Email is required',
            passwordRequired: 'Password is required',
            caseTypeRequired: 'Case Type is required',
            hostRequired: 'Host is required',
            portRequired: 'Port is required',
            duplicateEmailFound: 'The Email you provided already exists!',
            emailPlaceholder: 'username@zoho.com',
            emails: 'Email:',
            password: 'Password:',
            host: 'Server/Host:',
            port: 'Port:',
            encryption: 'Encryption:',
            ssl: 'SSL',
            tls: 'TLS',
            emailCaseType: 'Email Case Type:',
            username: 'Username:',
            smtpHostPlaceholder: 'example smtp.zoho.com',
            smtpPortPlaceholder: 'example 587',
            imapHostPlaceholder: 'example imap.zoho.com',
            imapPortPlaceholder: 'example 993',
            createdAt: 'Created At',
            caseType: 'Case Type',
            project: 'Project',
            areYouSureToDeleteThisEmail: 'Are You Sure To Delete This Email?',
            deleteSuccessfully: 'Email deleted successfully!',
            errorDeletingThisEmail: 'Failed to delete email!',
        },
        integrations: {
            areYouSureYouWantToDisconnectWithChain: 'Are you sure you want to disconnect with genius chain ',
            appName: 'App Name',
            status: 'Status',
            totalTransactions: 'Total Transactions',
            reauthorize: 'Reauthorize',
            disconnect: 'Disconnect',
            caseTypes: 'Case Types',
            caseType: 'Case Type',
            activity: 'Activity',
            selectActivity: 'Select Activity',
            selectProject: 'Select Project',
            projects: 'Projects',
            project: 'Project',
            newProject: 'New Project',
            newCaseType: 'New Case Type',
            procedure: 'Procedure',
            selectProcedure: 'Select Procedure',
            caseClose: 'Case Close',
            caseOpen: 'Case Open',
            newProjectAdded: 'New Project Added',
            configuredProjects: 'Configured Projects',
            notConfigured: 'Not Configured',
            projectAlreadyExists: 'Project configuration Already Exists',
            configureMapping: 'Configure Mapping',
            procedureVersion: 'Procedure Version',
            selectProcedureVersion: 'Select Procedure Version',
            version: 'Version ({{version}})',
            selectAttribute: 'Select Attribute',
            mappingConfiguredSuccessfully: 'Mapping Configured Successfully',
            areYouSureToDeleteAllConfiguredProjects: 'Are you sure to delete all configured projects?',
            deletedConfiguredProjectsSuccessfully: 'Deleted Configured Projects Successfully',
            failedToDeleteConfiguredProjects: 'Failed to delete configured projects',
            deleteConfiguredProjects: 'Delete Configured Projects',
            noConfiguredProjects: 'No Configured',
            areYouSureToDeleteThisConfiguredProjects: 'Are you sure to delete this configured project?',
            deletedConfiguredProjectSuccessfully: 'Deleted Configured Project Successfully',
            failedToDeleteConfiguredProject: 'Failed to delete configured project',
        },
        generateCertificate: {
            next: 'Next',
            pleaseEnterFullName: 'Please enter your full name',
            fullNameArabicInputLabel: 'Full Name in Arabic',
            fullNameEnglishInputLabel: 'Full Name in English',
            youDontHaveArabicNameToGenerate: 'Your Arabic Name is not found To Generate Certificate in arabic',
            cannotChangeNameNote: 'You cannot change your name once saved.',
            arabicNameMandatory: 'Entering an Arabic name is mandatory.',
            theNameMustEnterInArabicCharacters: 'The Arabic name can contain only Arabic characters.',
            theNameMustEnterInEnglishCharacters: 'The English name can contain only  English characters.',
            instructions: 'Instructions',
            nameMustAtLast: 'The name must be at least 5 characters long.',
            nameShouldNotContainNumbers: 'The name should not contain numbers or symbols.',
            pleaseEnterYouName: 'Please enter your name',
            notes: 'Notes',
            passCertificate: 'Pass Certificate',
            view: 'View',
            share: 'Share',
            areYouSureToSaveThisName: 'Are you sure you want to save the names you entered?',
            areYouSureToSaveThisNameContent: 'You cannot change your name once saved!',
            arabic: 'Arabic',
            english: 'English',
            chinese: 'Chinese',
            generate: 'Generate',
            selectCertificateLanguage: 'Please choose certificate language ',
            generatingYourCertificate: 'Generating your certificate!',
            thisWillTakeFewSeconds: 'This will take a few seconds...',
            certificateGenerated: 'Certificate Generated!',
            name: 'Name: ',
            date: 'Date: ',
            moduleName: 'Module name: ',
        },
    },
};
